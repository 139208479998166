const MESSAGES_BY_CRAWLERS = {
  brandwatch: 'This data can take up to 15 minutes to load!',
  datashake: 'This data can take up to 1 hour to load!',
  socialgist: 'This data can take up to 48 hours to load!',
}

const DOMAIN_TO_CRAWLERS_MAP = {
  // datashake (87)
  'yelp.com': 'datashake',
  'google.com': 'datashake',
  'agoda.com': 'datashake',
  'airbnb.com': 'datashake',
  'aliexpress.com': 'datashake',
  'angi.com': 'datashake',
  'apartmentratings.com': 'datashake',
  'avvo.com': 'datashake',
  'bilbayt.com': 'datashake',
  'booking.com': 'datashake',
  'capterra.com': 'datashake',
  'cargurus.com': 'datashake',
  'cars.com': 'datashake',
  'citysearch.com': 'datashake',
  'classpass.com': 'datashake',
  'consumeraffairs.com': 'datashake',
  'creditkarma.com': 'datashake',
  'dealerrater.com': 'datashake',
  'deliveroo.ae': 'datashake',
  'ebay.com': 'datashake',
  'edmunds.com': 'datashake',
  'etsy.com': 'datashake',
  'expedia.com': 'datashake',
  'facebook.com': 'datashake',
  'lawyers.findlaw.com': 'datashake',
  'foursquare.com': 'datashake',
  'gartner.com': 'datashake',
  'glassdoor.com': 'datashake',
  'healthgrades.com': 'datashake',
  'homeadvisor.com': 'datashake',
  'vrbo.com': 'datashake',
  'homestars.com': 'datashake',
  'hotels.com': 'datashake',
  'houzz.com': 'datashake',
  'indeed.com': 'datashake',
  'influenster.com': 'datashake',
  'insiderpages.com': 'datashake',
  'lawyers.com': 'datashake',
  'lendingtree.com': 'datashake',
  'martindale.com': 'datashake',
  'niche.com': 'datashake',
  'opentable.com': 'datashake',
  'producthunt.com': 'datashake',
  'productreview.com.au': 'datashake',
  'ratemds.com': 'datashake',
  'realself.com': 'datashake',
  'reserveout.com': 'datashake',
  'sitejabber.com': 'datashake',
  'softwareadvice.com': 'datashake',
  'talabat.com': 'datashake',
  'theknot.com': 'datashake',
  'thumbtack.com': 'datashake',
  'tripadvisor.com': 'datashake',
  'trulia.com': 'datashake',
  'trustedshops.com': 'datashake',
  'trustpilot.com': 'datashake',
  'trustradius.com': 'datashake',
  'vitals.com': 'datashake',
  'walmart.com': 'datashake',
  'doctor.webmd.com': 'datashake',
  'weddingwire.com': 'datashake',
  'yell.com': 'datashake',
  'yellowpages.com': 'datashake',
  'zillow.com': 'datashake',
  'zocdoc.com': 'datashake',
  'zomato.com': 'datashake',
  'play.google.com': 'datashake',
  'bbb.org': 'datashake',
  'g2.com': 'datashake',
  'target.com': 'datashake',
  'flipkart.com': 'datashake',
  'customerlobby.com': 'datashake',
  'feedback.ebay.com': 'datashake',
  'g2crowd.com': 'datashake',
  'alternativeto.net': 'datashake',
  'apartments.com': 'datashake',
  'greatschools.org': 'datashake',
  'homeaway.co.uk': 'datashake',
  'newegg.com': 'datashake',
  'itcentralstation.com': 'datashake',
  'jet.com': 'datashake',
  'siftery.com': 'datashake',
  'openrice.com': 'datashake',
  'tripadvisor.fr': 'datashake',
  'bol.com': 'datashake',
  'kayak.com': 'datashake',
  'smythstoys.com': 'datashake',
  'apps.apple.com': 'datashake',
  'goodreads.com': 'datashake',
  // Socialgist (16)
  'store.steampowered.com': 'socialgist',
  'magazineluiza.com.br': 'socialgist',
  'rakuten.co.jp': 'socialgist',
  'auchan.fr': 'socialgist',
  'kakaku.com': 'socialgist',
  'costco.com': 'socialgist',
  'sephora.fr': 'socialgist',
  'item.gome.com.cn': 'socialgist',
  'boulanger.com': 'socialgist',
  'petco.com': 'socialgist',
  'dianping.com': 'socialgist',
  'sephora.com': 'socialgist',
  'petsmart.com': 'socialgist',
  'reviews.io': 'socialgist',
  'reviewcentre.com': 'socialgist',
  'homedepot.com': 'socialgist',
  // Brandwatch (41)
  'amazon.ae': 'brandwatch',
  'amazon.ca': 'brandwatch',
  'amazon.cn': 'brandwatch',
  'amazon.co.jp': 'brandwatch',
  'amazon.co.uk': 'brandwatch',
  'amazon.com': 'brandwatch',
  'amazon.com.au': 'brandwatch',
  'amazon.com.br': 'brandwatch',
  'amazon.com.mx': 'brandwatch',
  'amazon.de': 'brandwatch',
  'amazon.es': 'brandwatch',
  'amazon.fr': 'brandwatch',
  'amazon.in': 'brandwatch',
  'amazon.it': 'brandwatch',
  'amazon.sg': 'brandwatch',
  'chewy.com': 'brandwatch',
  'bestbuy.com': 'brandwatch',
  'bestbuy.ca': 'brandwatch',
  'item.jd.com': 'brandwatch',
  'ocado.com': 'brandwatch',
  'groceries.asda.com': 'brandwatch',
  'tesco.com': 'brandwatch',
  'shopee.com.my': 'brandwatch',
  'shopee.co.id': 'brandwatch',
  'shopee.ph': 'brandwatch',
  'shopee.sg': 'brandwatch',
  'shopee.co.th': 'brandwatch',
  'shopee.vn': 'brandwatch',
  'amazon.com.be': 'brandwatch',
  'amazon.sa': 'brandwatch',
  'amazon.com.tr': 'brandwatch',
  'amazon.nl': 'brandwatch',
  'amazon.eg': 'brandwatch',
  'amazon.pl': 'brandwatch',
  'amazon.se': 'brandwatch',
}

const getDomainFromURL = (url) => {
  if (!url) {
    return ''
  }

  let cleanedUrl = url

  if (!cleanedUrl.startsWith('http://') && !cleanedUrl.startsWith('https://')) {
    cleanedUrl = `http://${cleanedUrl}`
  }

  cleanedUrl = cleanedUrl.replace(/www\./, '').trim()

  try {
    const { host: domain } = new URL(cleanedUrl)

    return domain
  } catch (error) {
    return ''
  }
}

const getLoadTimeMessageOfUrl = (url) => {
  const domain = getDomainFromURL(url)
  const crawlerKey = DOMAIN_TO_CRAWLERS_MAP[domain]
  return MESSAGES_BY_CRAWLERS[crawlerKey] || ''
}

export default getLoadTimeMessageOfUrl
