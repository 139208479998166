<template>
  <r-box
    v-if="loading"
    class="addproduct"
  >
    <v-skeleton-loader
      type="table-heading, list-item-two-line"
    />
    <div class="d-flex justify-end">
      <v-skeleton-loader
        type="button"
        class="mr-2"
      />
      <v-skeleton-loader type="button" />
    </div>
  </r-box>
  <r-box
    v-else
    class="addproduct"
  >
    <v-form
      ref="form"
      @submit="(e) => e.preventDefault()"
    >
      <r-section
        :label="$t('addProduct.name.title')"
        class="pb-2"
      >
        <r-text-field
          class="addproduct--name"
          :placeholder="$t('addProduct.name.placeholder')"
          :value="name"
          :rules="productName"
          @change="onChangeProductName"
        />
      </r-section>

      <r-section
        :label="$t('addProduct.addUrls.title')"
        class="pb-4"
      >
        <label>{{ $t('addProduct.addUrls.label') }}</label>
        <div
          v-for="(url, i) in urls"
          :key="`addproduct-item-${i}`"
          class="addproduct--item mb-2"
        >
          <r-text-field
            class="addproduct--url"
            :value="url"
            :error-message="errorMessages[i]"
            :placeholder="`${$t('global.abbreviations.example')}: https://amazon.com/dp/ASIN`"
            :rules="isUrlLastAndEmpty(i) ? [] : urlItem"
            :hint="getLoadTimeMessageOfAUrl(url)"
            @change="(data) => onChangeUrlInput(i, data)"
          />
          <v-icon
            v-if="urls.length > 1"
            class="addproduct--delete"
            :disabled="fetchingValidateUrls"
            @click="onClickRemove(i)"
          >
            delete
          </v-icon>
        </div>
        <r-button
          class="addproduct--addmore"
          type="primary"
          :label="$t('global.actions.addMore')"
          icon="mdi-plus"
          :outlined="true"
          :loading="false"
          :disabled="!allUrlsValid"
          @click="onClickAddMore"
        />
      </r-section>

      <r-section
        :label="$t('addProduct.csvUpload.title')"
        class="pb-4"
      >
        <r-file-upload
          class="addproduct--fileupload"
          :label="$t('componentLibrary.uploadFileLabel')"
          :placeholder="$t('componentLibrary.uploadFilePlaceholder')"
          :filetypes="['.txt', '.csv']"
          :select-file-label="$t('componentLibrary.uploadFileSelectLabel')"
          :upload-button-label="$t('componentLibrary.uploadButtonLabel')"
          @upload="onFileUpload"
        />
      </r-section>

      <r-section
        v-if="isEdit"
        label="This product will be edited in the following projects"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              v-for="item in productProjects"
              :key="item.value"
            >
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </r-section>
      <r-section
        v-else
        :label="$t('addUrls.addToAProject')"
        class="pb-8"
      >
        <label>{{ $t('addUrls.addToProjectLabel') }}</label>
        <project-select
          class="addproduct--projectselect mt-2"
          @change="onChangeSelectedProject"
        />
      </r-section>
    </v-form>

    <r-error-message
      class="addproduct--errormessage"
      :errors="errors"
    />

    <div class="d-flex justify-end mt-3">
      <r-button
        class="addproduct--cancel mr-2"
        type="primary"
        :label="$t('global.actions.cancel')"
        :outlined="true"
        @click="onClickCancel"
      />
      <r-button
        class="addproduct--save"
        type="primary"
        :label="$t('global.actions.save')"
        :disabled="disableSaveButton"
        :loading="fetchingCreateProduct || fetchingValidateUrls"
        @click="onClickSave"
      />
    </div>
  </r-box>
</template>

<script>
import ProjectSelect from '@/components/app/data/ProjectSelect'
import RBox from '@/components/library/molecules/RBox'
import RButton from '@/components/library/atoms/RButton'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import RFileUpload from '@/components/library/molecules/RFileUpload'
import RSection from '@/components/library/molecules/RSection'
import RTextField from '@/components/library/molecules/RTextField'
import { mapActions, mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import getLoadTimeMessageOfUrl from '@/utils/getLoadTimeMessageOfUrl'

const includeHttps = (url) => `https://${url.replace(/^https?:\/\//, '')}`

const isValidUrl = (str) => {
  if (!str) {
    return false
  }
  try {
    const url = new URL(includeHttps(str))
    return url.protocol.startsWith('http') && url.hostname.includes('.')
  } catch (_) {
    return false
  }
}

export default {
  name: 'AddEditProductContent',
  components: {
    RBox,
    RSection,
    RTextField,
    RButton,
    RFileUpload,
    ProjectSelect,
    RErrorMessage,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      name: '',
      urls: [''],
      projectId: '',
      errorMessages: [''],
      isEdit: false,
      urlItem: [
        (value) => !!value || this.$i18n.t('global.validations.messages.required'),
        (value) => isValidUrl(value) || this.$i18n.t('global.validations.messages.invalidURL'),
      ],
      productName: [
        (value) => !!value || this.$i18n.t('global.validations.messages.required'),
      ],
      productProjects: [],
      csvFileUploadedLength: 0,
    }
  },
  computed: {
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapState('urls', [
      'fetchingValidateUrls',
      'validateUrlsErrors',
      'validUrls',
    ]),
    ...mapState('products', [
      'fetchingCreateProduct',
      'fetchCreateProductError',
      'fetchingProductsUpdate',
      'fetchingProductsUpdateError',
    ]),
    ...mapState('projects', [
      'projects',
    ]),
    allUrlsValid() {
      return this.urls.every(isValidUrl)
    },
    validUrlsExcludingLast() {
      return this.urlsToSave.every(isValidUrl)
    },
    urlsToSave() {
      const urls = [...this.urls]
      // We do not save the last url if it is empty.
      if (urls.length > 1 && !urls.at(-1)) {
        urls.pop()
      }
      return urls
    },
    disableSaveButton() {
      const isLoading = this.fetchingCreateProduct || this.fetchingValidateUrls
      return isLoading || !this.validUrlsExcludingLast || !this.name
    },
    loading() {
      return this.fetchingCreateProduct || this.fetchingValidateUrls || this.fetchingProductsUpdate
    },
    errors() {
      return [this.fetchCreateProductError, this.fetchingProductsUpdateError]
    },
  },
  beforeMount() {
    this.$data.name = this.product.name || ''
    this.$data.isEdit = !!this.product.name
  },
  beforeDestroy() {
    this.clearErrors({ fetchCreateProductError: '' })
  },
  mounted() {
    this.$data.projectId = this.selectedProjectId
    if (this.isEdit) {
      this.mapProductProjects()
    }
  },
  methods: {
    ...mapActions('urls', ['validateURLs']),
    ...mapActions('products', [
      'createProduct',
      'clearErrors',
      'updateProduct',
    ]),
    getLoadTimeMessageOfAUrl(url) {
      return getLoadTimeMessageOfUrl(url)
    },
    onChangeProductName({ value }) {
      this.$data.name = value
    },
    onChangeSelectedProject({ value }) {
      this.$data.projectId = value
    },
    resetServerSideValidation() {
      this.urls = this.urls.map((url) => (url ? includeHttps(url) : ''))
    },
    async runServerSideValidation() {
      this.resetServerSideValidation()

      const urls = this.urlsToSave
      await this.validateURLs({ urls, validateAlreadyExistingUrls: false })

      if (this.validateUrlsErrors.length) {
        this.$data.errorMessages = this.$data.urls.map((_, index) => {
          const error = this.validateUrlsErrors.find(({ urlIndex }) => urlIndex === index)
          if (!error) {
            return ''
          }

          const { message } = error
          return message
        })
      } else {
        this.$data.urls = [...this.validUrls]
        this.$data.errorMessages = ['']
      }
    },
    async onClickAddMore() {
      await this.runServerSideValidation()
      if (!this.validateUrlsErrors.length) {
        this.$data.urls.push('')
      }
    },
    onChangeUrlInput(index, { value: url }) {
      Vue.set(this.$data.urls, index, url)
    },
    onClickRemove(index) {
      this.errorMessages = this.errorMessages.filter((_, i) => i !== index)
      this.urls = this.urls.filter((_, i) => i !== index)
    },
    async onUpdateProduct({ product }) {
      const payload = {
        ...product,
        productId: this.$props.product._id,
      }

      await this.updateProduct(payload)
      if (!this.fetchingProductsUpdateError) {
        this.$emit('on:product-submitted')
      }
    },
    async onCreateProduct({ product }) {
      const { projectId } = this.$data
      const payload = {
        ...product,
        ...(projectId && { projectId }),
      }

      await this.createProduct(payload)
      if (!this.fetchCreateProductError) {
        this.$emit('on:product-submitted')
      }
    },
    onFileUpload({ data }) {
      let lines = data.split('\n').filter((v) => !!v)
      lines = lines.map((line) => line.replace('\r', ''))
      this.$data.urls = this.$data.urls.filter((v) => !!v).concat(lines)
      this.runServerSideValidation()
      this.csvFileUploadedLength += lines.length
    },
    async onClickSave() {
      if (!this.$refs.form.validate()) {
        return
      }

      await this.runServerSideValidation()
      if (this.validateUrlsErrors.length) {
        return
      }

      const { name } = this.$data
      const urls = this.urlsToSave
      const product = {
        name,
        urls,
      }

      if (this.isEdit) {
        await this.onUpdateProduct({ product })
      } else {
        await this.onCreateProduct({ product })
      }
    },
    isUrlLastAndEmpty(i) {
      const { urls } = this.$data
      return (
        urls.length > 1
        && urls.length - 1 === i
        && !urls.at(-1))
    },
    onClickCancel() {
      this.$emit('on:cancel-product-create')
    },
    mapProductProjects() {
      const projects = []
      this.projects.forEach((project) => {
        if (this.product.projectIds.includes(project._id)) {
          projects.push({ title: project.name, value: project._id })
        }
      })
      this.productProjects = projects
    },
  },
}
</script>
<style scoped>
.addproduct {
  width: 640px;
  min-height: 60%;
  margin: 0 auto;
}
.addproduct--item {
  position: relative;
}
.addproduct--delete {
  position: absolute;
  top: 9px;
  right: 8px;
}
</style>
