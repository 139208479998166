var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading ? _c('r-box', {
    staticClass: "addproduct"
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "table-heading, list-item-two-line"
    }
  }), _c('div', {
    staticClass: "d-flex justify-end"
  }, [_c('v-skeleton-loader', {
    staticClass: "mr-2",
    attrs: {
      "type": "button"
    }
  }), _c('v-skeleton-loader', {
    attrs: {
      "type": "button"
    }
  })], 1)], 1) : _c('r-box', {
    staticClass: "addproduct"
  }, [_c('v-form', {
    ref: "form",
    on: {
      "submit": function submit(e) {
        return e.preventDefault();
      }
    }
  }, [_c('r-section', {
    staticClass: "pb-2",
    attrs: {
      "label": _vm.$t('addProduct.name.title')
    }
  }, [_c('r-text-field', {
    staticClass: "addproduct--name",
    attrs: {
      "placeholder": _vm.$t('addProduct.name.placeholder'),
      "value": _vm.name,
      "rules": _vm.productName
    },
    on: {
      "change": _vm.onChangeProductName
    }
  })], 1), _c('r-section', {
    staticClass: "pb-4",
    attrs: {
      "label": _vm.$t('addProduct.addUrls.title')
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t('addProduct.addUrls.label')))]), _vm._l(_vm.urls, function (url, i) {
    return _c('div', {
      key: "addproduct-item-".concat(i),
      staticClass: "addproduct--item mb-2"
    }, [_c('r-text-field', {
      staticClass: "addproduct--url",
      attrs: {
        "value": url,
        "error-message": _vm.errorMessages[i],
        "placeholder": "".concat(_vm.$t('global.abbreviations.example'), ": https://amazon.com/dp/ASIN"),
        "rules": _vm.isUrlLastAndEmpty(i) ? [] : _vm.urlItem,
        "hint": _vm.getLoadTimeMessageOfAUrl(url)
      },
      on: {
        "change": function change(data) {
          return _vm.onChangeUrlInput(i, data);
        }
      }
    }), _vm.urls.length > 1 ? _c('v-icon', {
      staticClass: "addproduct--delete",
      attrs: {
        "disabled": _vm.fetchingValidateUrls
      },
      on: {
        "click": function click($event) {
          return _vm.onClickRemove(i);
        }
      }
    }, [_vm._v(" delete ")]) : _vm._e()], 1);
  }), _c('r-button', {
    staticClass: "addproduct--addmore",
    attrs: {
      "type": "primary",
      "label": _vm.$t('global.actions.addMore'),
      "icon": "mdi-plus",
      "outlined": true,
      "loading": false,
      "disabled": !_vm.allUrlsValid
    },
    on: {
      "click": _vm.onClickAddMore
    }
  })], 2), _c('r-section', {
    staticClass: "pb-4",
    attrs: {
      "label": _vm.$t('addProduct.csvUpload.title')
    }
  }, [_c('r-file-upload', {
    staticClass: "addproduct--fileupload",
    attrs: {
      "label": _vm.$t('componentLibrary.uploadFileLabel'),
      "placeholder": _vm.$t('componentLibrary.uploadFilePlaceholder'),
      "filetypes": ['.txt', '.csv'],
      "select-file-label": _vm.$t('componentLibrary.uploadFileSelectLabel'),
      "upload-button-label": _vm.$t('componentLibrary.uploadButtonLabel')
    },
    on: {
      "upload": _vm.onFileUpload
    }
  })], 1), _vm.isEdit ? _c('r-section', {
    attrs: {
      "label": "This product will be edited in the following projects"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', _vm._l(_vm.productProjects, function (item) {
    return _c('v-list-item-title', {
      key: item.value
    }, [_vm._v(" " + _vm._s(item.title) + " ")]);
  }), 1)], 1)], 1) : _c('r-section', {
    staticClass: "pb-8",
    attrs: {
      "label": _vm.$t('addUrls.addToAProject')
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t('addUrls.addToProjectLabel')))]), _c('project-select', {
    staticClass: "addproduct--projectselect mt-2",
    on: {
      "change": _vm.onChangeSelectedProject
    }
  })], 1)], 1), _c('r-error-message', {
    staticClass: "addproduct--errormessage",
    attrs: {
      "errors": _vm.errors
    }
  }), _c('div', {
    staticClass: "d-flex justify-end mt-3"
  }, [_c('r-button', {
    staticClass: "addproduct--cancel mr-2",
    attrs: {
      "type": "primary",
      "label": _vm.$t('global.actions.cancel'),
      "outlined": true
    },
    on: {
      "click": _vm.onClickCancel
    }
  }), _c('r-button', {
    staticClass: "addproduct--save",
    attrs: {
      "type": "primary",
      "label": _vm.$t('global.actions.save'),
      "disabled": _vm.disableSaveButton,
      "loading": _vm.fetchingCreateProduct || _vm.fetchingValidateUrls
    },
    on: {
      "click": _vm.onClickSave
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }