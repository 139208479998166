var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rfileupload"
  }, [_vm.label ? _c('label', {
    attrs: {
      "for": _vm.randomId
    }
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _c('input', {
    ref: "fileInput",
    attrs: {
      "id": _vm.randomId,
      "type": "file",
      "hidden": "",
      "accept": _vm.acceptTypes
    },
    on: {
      "change": _vm.onUploadFile
    }
  }), _c('v-text-field', {
    staticClass: "my-1",
    attrs: {
      "placeholder": _vm.placeholder,
      "solo": "",
      "outlined": "",
      "dense": "",
      "flat": "",
      "readonly": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.fileInput.click();
      }
    },
    model: {
      value: _vm.filename,
      callback: function callback($$v) {
        _vm.filename = $$v;
      },
      expression: "filename"
    }
  }), _c('r-button', {
    attrs: {
      "label": _vm.selectFileLabel,
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.fileInput.click();
      }
    }
  }), _c('r-button', {
    staticClass: "ml-2",
    attrs: {
      "label": _vm.uploadButtonLabel,
      "disabled": _vm.uploadDisabled
    },
    on: {
      "click": _vm.onClickUpload
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }