var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('r-select', {
    staticClass: "projectselect",
    attrs: {
      "items": _vm.projectsItems,
      "allow-empty": false,
      "infinite": true
    },
    on: {
      "change": _vm.onChange,
      "change:search": _vm.onSearchChange,
      "on:get-more": _vm.oneGetMoreItems
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }