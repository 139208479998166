var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "addproduct--container"
  }, [_c('add-product-content', {
    on: {
      "on:product-submitted": _vm.onProductCreated,
      "on:cancel-product-create": _vm.onCancelProductCreate
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }