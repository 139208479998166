var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rsection",
    class: {
      enabletransitions: _vm.enabletransitions
    }
  }, [_c('r-section-header', {
    attrs: {
      "label": _vm.label
    },
    scopedSlots: _vm._u([_vm.collapsable ? {
      key: "end",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "data-testid": "collapse-button"
          },
          on: {
            "click": _vm.toggleCollapse
          }
        }, [_vm._v(" " + _vm._s(_vm.isCollapsed ? 'mdi-chevron-down' : 'mdi-chevron-up') + " ")])];
      },
      proxy: true
    } : null], null, true)
  }), _c('div', {
    ref: "contentwrapper",
    staticClass: "contentwrapper",
    class: {
      collapsed: _vm.isCollapsed
    },
    style: {
      'max-height': "".concat(_vm.isCollapsed ? '0px' : _vm.cardHeight())
    }
  }, [_vm._t("default"), _vm._t("content")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }